<template>
  <b-container fluid>
    <router-view />
  </b-container>
</template>

<script>
export default {

}
</script>
